import React from "react";
// Styling
import "./UpdatePassword.scss";
// Components
import {ReactComponent as Logo} from "../../Assets/icons/Logo-Tinker.svg";
import {InputGroup, Input, Button, Icon, Whisper, Tooltip} from "rsuite";
import {
    styleTooltip,
    styleInnerParagraph,
    styleInnerIconWrong,
    styleInnerIconRight,
    tooltipHeader
} from '../../Utils/resetStyles';
import {toast} from 'react-toastify'
import Axios from "axios";

class UpdatePassword extends React.Component {

    state = {
        parolaActuala: '',
        parolaNoua: '',
        confirmParola: '',
        passwordLength: false,
        containsNumbers: false,
        isUppercase: false,
        containsSymbols: false,
        repetaParola: '',
        termeni: false,
        pressed: false,
        email: "",
        valid: true,
        validConfirm: true,
        showPass: false,
    }

    handleChangePassword = (value, event) => {
        var number = value.match(/\d+/g);
        var uppercase = value.match(/[A-Z]/);
        var symbols = new RegExp(/[^A-Z a-z0-9]/);
        this.setState({
            parolaNoua: value,
            passwordLength: value.length > 7 ? true : false,
            containsNumbers: number != null ? true : false,
            isUppercase: uppercase != null ? true : false,
            containsSymbols: symbols.test(value) ? true : false,
            valid: value.length > 7 && number != null && uppercase != null && symbols.test(value),
            validConfirm: value === this.state.confirmParola
        });
    };

    updatePassword = async () => {
        if (
            this.state.email === '' ||
            this.state.confirmParola === '' ||
            this.state.parolaNoua === ''
        ) {
            toast.error('Ați lasat câmpuri necompletate');
            return;
        }

        if (
            this.state.passwordLength === false ||
            this.state.containsNumbers === false ||
            this.state.isUppercase === false ||
            this.state.containsSymbols === false
        ) {
            toast.error(
                'Parola trebuie sa contină cel puțin 8 caractere, o literă mare, o cifră și un caracter special '
            );
            return;
        }

        if (this.state.confirmParola !== this.state.parolaNoua) {
            toast.error('Cele doua parole nu sunt identice');
            return;
        }


        let toSend = {
            email: this.state.email,
            newPassword: this.state.parolaNoua,
            confirmParola: this.state.confirmParola,
            token: this.props.match.params.id
        };

        try {
            await Axios.post(process.env.REACT_APP_STRAPI_URL + '/user-info/updatePasswordFromReset', toSend)

            toast.success("Parola modificata cu succes")
            this.props.history.push('/')
            // this.props.history.push('/2fa-config')
        } catch (e) {

            toast.error("A apărut o eroare!")
        }
    }

    handleChange = (value, event) => {

        this.setState({
            [event.target.name]: value
        })

        if(event.target.name === 'confirmParola') {
            if(this.state.parolaNoua === value) {
                this.setState({
                    validConfirm: true
                })
            } else {
                this.setState({
                    validConfirm: false
                })
            }
        }
    }

    render() {
        return (
            <div className="update-psw-page">
                <div className="update-psw-logo-container">
                    <Logo className="update-psw-logo"/>
                </div>

                <div className="update-psw-content-container">
                    <h1>Setează o parola noua</h1>

                    <div className="update-psw-form-container">
                        {/* <InputGroup className="update-psw-input-group">
              <InputGroup.Addon>
                <Icon icon="avatar" />
              </InputGroup.Addon>
              <Input placeholder="Nume utilizator" />
            </InputGroup> */}

                        <InputGroup className="update-psw-input-group">
                            <InputGroup.Addon>
                                <Icon icon="lock"/>
                            </InputGroup.Addon>
                            <Input onChange={this.handleChange} name="email" type="email" placeholder="Email cont"/>
                        </InputGroup>

                        <Whisper
                            trigger='click'
                            speaker={
                                <Tooltip>
                                    <h6 style={tooltipHeader}>Parola trebuie sa contină:</h6>
                                    <div style={styleTooltip} className='criteria'>
                                        {this.state.passwordLength ? (
                                            <Icon style={styleInnerIconWrong} icon='check'/>
                                        ) : (
                                            <Icon style={styleInnerIconRight} icon='close'/>
                                        )}
                                        <p style={styleInnerParagraph}>cel puțin 8 caractere</p>
                                    </div>
                                    <div style={styleTooltip} className='criteria'>
                                        {this.state.containsNumbers ? (
                                            <Icon style={styleInnerIconWrong} icon='check'/>
                                        ) : (
                                            <Icon style={styleInnerIconRight} icon='close'/>
                                        )}
                                        <p style={styleInnerParagraph}>cel puțin un număr</p>
                                    </div>
                                    <div style={styleTooltip} className='criteria'>
                                        {this.state.isUppercase ? (
                                            <Icon style={styleInnerIconWrong} icon='check'/>
                                        ) : (
                                            <Icon style={styleInnerIconRight} icon='close'/>
                                        )}
                                        <p style={styleInnerParagraph}>cel puțin o literă mare</p>
                                    </div>
                                    <div style={styleTooltip} className='criteria'>
                                        {this.state.containsSymbols ? (
                                            <Icon style={styleInnerIconWrong} icon='check'/>
                                        ) : (
                                            <Icon style={styleInnerIconRight} icon='close'/>
                                        )}
                                        <p style={styleInnerParagraph}>cel puțin un simbol</p>
                                    </div>
                                </Tooltip>
                            }
                        >

                            <InputGroup
                                className={"update-psw-input-group " + (this.state.valid ? '' : 'input-invalid')}>
                                <InputGroup.Addon>
                                    <Icon icon="lock"/>
                                </InputGroup.Addon>

                                <div className={"input-show-pass-icon"} onClick={() => this.setState({showPass: !this.state.showPass})}><Icon icon="eye" size={15}/></div>

                                {this.state.valid ?
                                    this.state.parolaNoua.length > 0 ?
                                    <div className={"input-valid-icon"}><Icon icon="check" size={15}/></div>
                                        :null
                                    :
                                    <div className={"input-invalid-icon"}><Icon icon="close" size={15}/></div>
                                }
                                <Input onChange={this.handleChangePassword} name="parolaNoua" type={this.state.showPass ? 'text' : 'password'}
                                       placeholder="Parola noua"/>
                            </InputGroup>
                        </Whisper>


                        <InputGroup className={"update-psw-input-group " + (this.state.validConfirm ? '' : 'input-invalid')}>
                            <InputGroup.Addon>
                                <Icon icon="lock"/>
                            </InputGroup.Addon>
                            {this.state.validConfirm ?
                                this.state.confirmParola.length > 0 ?
                                    <div className={"input-valid-icon"}><Icon icon="check" size={15}/></div>
                                    :null
                                :
                                <div className={"input-invalid-icon"}><Icon icon="close" size={15}/></div>
                            }
                            <Input onChange={this.handleChange} name="confirmParola" type='password'
                                   placeholder="Confirma Parola noua"/>
                        </InputGroup>
                    </div>

                    <div className="update-psw-btn-container">
                        <Button onClick={this.updatePassword} className="update-psw-btn">Salveaza</Button>
                    </div>
                </div>
            </div>
        );

    }


}

export default UpdatePassword;
