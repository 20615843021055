import React, {Suspense, lazy} from "react";
//import logo from './logo.svg';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import "./App.scss";
/* Dependencies */
import {ToastContainer} from "react-toastify";
/* Loader */
import Loader from "react-loader-spinner";
import UpdatePassword from "./Pages/Onboarding/UpdatePassword";
// Rect Draft - text editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ChangeEmail from "./Pages/Onboarding/ChangeEmail";
import axios from "axios";
import {isJsonString} from "./Utils/utils";

/* Components */
const Demo = lazy(() => import("./Pages/Onboarding/Demo/Demo"));
const ResetPassword = lazy(() => import("./Pages/Onboarding/ResetPassword"));
const SelectTypeOfAccount = lazy(() =>
    import("./Pages/Onboarding/SelectTypeOfAccount")
);
const SetAccountData = lazy(() => import("./Pages/Onboarding/SetAccountData"));
const Dashboard = lazy(() => import("./Pages/Main/Dashboard"));
const PrivateDashboard = lazy(() => import("./UnusedScreens/PrivateDashboard"));
const TermsAndConditions = lazy(() =>
    import("./Pages/Onboarding/Components/TermsAndConditions")
);
const Login = lazy(() => import("./Pages/Onboarding/Scoala179/Login"));
const LoginFromAdmin = lazy(() => import("./Pages/Onboarding/Scoala179/LoginFromAdmin"));
const UpdatePswAfterFirstLogin = lazy(() =>
    import("./Pages/Onboarding/UpdatePswAfterFirstLogin")
);
const ExpireLicense = lazy(() =>
    import("./Pages/Onboarding/ExpireLicense")
);
const ParentDashboard = lazy(() => import("./UnusedScreens/ParentDashboard"));
const ElevDashboard = lazy(() => import("./UnusedScreens/ElevDashboard"));
const Eror404 = lazy(() => import("./Pages/Error404"));
const SignUp = lazy(() => import("./Pages/Onboarding/SignUp"));
const TwoFAConfig = lazy(() => import("./Pages/Onboarding/2fa/TwoFAConfig"));
const TwoFAVerify = lazy(() => import("./Pages/Onboarding/2fa/TwoFAVerify"));
const TwoFAActivation = lazy(() => import("./Pages/Onboarding/2fa/TwoFAActivation"));


class App extends React.Component {


    componentDidMount() {
      [window, document].forEach(
          el => el.addEventListener('message', (message) => {

            let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false
            if (data) {
              // eslint-disable-next-line
              switch (data.action) {
                case 'registerPushToken':
                  this.registerPushToken(data.token)
                  break
                case 'unregisterPushToken':
                  this.unregisterPushToken()
                  break
              }
            }
          }))
    }

    async registerPushToken(token) {
        let data = {
            token: token
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_STRAPI_URL + '/user-info/updatePushToken', data, auth)
    }

    async unregisterPushToken() {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }
        await axios.post(process.env.REACT_APP_STRAPI_URL + '/user-info/updatePushToken', {token: ''}, auth)
    }

    render() {
        return (
            <div className="App">
                <div className="content-container-col">
                    <Router>
                        <Suspense
                            fallback={
                                <Loader
                                    className="loader"
                                    type="Circles"
                                    color="#00BFFF"
                                    height={80}
                                    width={80}
                                    timeout={3000}
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                />
                            }
                        >
                            <ToastContainer/>
                            <Switch>
                                <Route path="/" exact component={Login}></Route>
                                <Route path="/loginFromAdmin" exact component={LoginFromAdmin}></Route>
                                <Route path="/SignUp" exact component={SignUp}></Route>
                                <Route path="/Demo" exact component={Demo}></Route>
                                <Route path="/ResetPassword" exact component={ResetPassword}></Route>
                                <Route path="/UpdatePassword" exact component={UpdatePswAfterFirstLogin}></Route>

                                {/* Onboarding route */}
                                <Route path="/SelectType" exact component={SelectTypeOfAccount}></Route>
                                <Route path="/SetAccountData" exact component={SetAccountData}></Route>
                                <Route path="/TermsAndConditions" exact component={TermsAndConditions}></Route>https://09v3v.mjt.lu/lnk/BAAABTzwmD4AAAAAAAAAADCLSEgAAAABZckAAAAAACTkFgBl3GlFhjNIHGF4T82S7QAr7OVbeAAifsQ/1/cUeLAOsEh00-vzK-jVb8dA/aHR0cHM6Ly9jYXRhbG9nLnNrb29sdmVycy5yby9jaGFuZ2VFbWFpbC9pbGllLm1hZGFsaW5hQHNvZnRjbGFpcmUuY29tLzcyNTk1Mg
                                <Route path="/login179" exact component={Login}/>
                                <Route path="/update-password" exact component={UpdatePswAfterFirstLogin}/>

                                {/* Dashboard */}
                                <Route path="/dashboard" exact component={Dashboard}></Route>
                                <Route path="/private" exact component={PrivateDashboard}></Route>
                                <Route path="/classes" component={PrivateDashboard}></Route>
                                <Route path="/courses" exact component={PrivateDashboard}></Route>
                                <Route path="/profile" exact component={PrivateDashboard}></Route>
                                <Route path="/suport" exact component={PrivateDashboard}></Route>

                                <Route path="/licenceExpire" exact component={ExpireLicense}></Route>
                                {/* Dashboard Parinte -> ruta provizorie */}
                                <Route path="/dashboard-parinte" exact component={ParentDashboard}/>
                                />
                                <Route path="/dashboard-elev" exact component={ElevDashboard}/>
                                <Route path="/updatePasswordEmail/:id" exact component={UpdatePassword}/>
                                <Route path="/changeEmail/:email/:id" exact component={ChangeEmail}/>

                                <Route path="/2fa-config" exact component={TwoFAConfig}/>
                                <Route path="/2fa-verify" exact component={TwoFAVerify}/>
                                <Route path="/2fa-activation" exact component={TwoFAActivation}/>

                                <Route path="/error-404" exact component={Eror404}/>
                                <Route component={Eror404}/>
                            </Switch>
                        </Suspense>
                    </Router>
                </div>
            </div>
        );
    }
}

export default App;
